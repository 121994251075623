import { PureComponent, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend } from "recharts";

function IntradayHeartRateChart(props: any) {
  const {t} = useTranslation();
  const [graphData, setGraphData] = useState<any>([]);

  useEffect(() => {
    const formattedData = Object.keys(props.data).map(time => ({
      time: time.substring(0, 5),
      heartRate: props.data[time]
    }));
    setGraphData(formattedData);
  }, [props]);

  return (
    <Row>
      <Col className="d-flex align-items-center justify-content-center">
        <ResponsiveContainer width="90%" height={400}>
          <LineChart data={graphData}>
            <XAxis dataKey="time" />
            <YAxis />
            <Tooltip />
            <Line type="monotone" dataKey="heartRate" stroke="#8884d8" />
            <Legend
              verticalAlign="top"
              align="right"
              content={() => (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ color: '#000', marginRight: '10px' }}>
                    {t('intradayHeartRate.beatsPerMinute')}
                  </div>
                </div>
              )}
            />
          </LineChart>
        </ResponsiveContainer>
      </Col>
    </Row>
  );
}

export default IntradayHeartRateChart;
