import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: process.env.REACT_APP_ENVIRONMENT !== "production",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: {
          webhooks: {
            webhookTests: "Webhook Tests",
            testUsersWebhook: "Test Users Webhook",
            testParametersWebhook: "Test Parameters Webhook",
            testActivitiesWebhook: "Test Activities Webhook",
            webhookType: {
              users: "Users Webhook",
              parameters: "Parameters Webhook",
              activities: "Activities Webhook",
            },
          },
          pagination: {
            previous: "Previous",
            next: "Next",
            page: "Page",
            showingResults: "Showing results",
          },
          weekdays: {
            sun: "Sun",
            mon: "Mon",
            tue: "Tue",
            wed: "Wed",
            thu: "Thu",
            fri: "Fri",
            sat: "Sat",
          },
          stepsChart: {
            starsChartTitle: "Activity Status",
            starsChartStatusSedentary: "Sedentary",
            starsChartStatusLow: "Low",
            starsChartStatusModerate: "Moderate",
            starsChartStatusHigh: "High",
            stepsThisWeek: "Steps this day",
            stepsLastWeek: "Avg. steps last week",
            minRecommendedValue: "Min. recommended steps",
            dayOfWeek: "Day of week",
            steps: "Steps",
            legend1:
              "* Activity status based on the last 7 days of total steps",
            legend2: "* Activity distribution based on 14 days of total steps",
          },
          sleepChart: {
            sleepScore: "Sleep Score",
            shortSleep: "Short Sleep",
            optimalSleep: "Optimal Sleep",
            longSleep: "Long Sleep",
            sleepDistribution: "Sleep Distribution",
            lessThan6Hours: "Less than 6 hours",
            between6And9Hours: "6 to 9 hours",
            moreThan9Hours: "More than 9 hours",
            legend1:
              "* Sleep score based on the last 3 days of total sleep duration",
            legend2:
              "* Sleep distribution based on the last 14 days of total sleep duration",
          },
          activitiesChart: {
            title: "Active Time Score",
            minutes: "minutes",
            title2: "Active Time Distribution",
            legend1:
              "* Active time score based on the recommendation of 180 minutes of activity per week",
            legend2:
              "* Active time distribution based on the last 7 days of activities duration",
          },
          intradayHeartRate: {
            title: "Intraday Heart Rate",
            beatsPerMinute: "bpm",
            time: "time",
          },
        },
      },
      es: {
        translation: {
          webhooks: {
            webhookTests: "Pruebas de Webhooks",
            testUsersWebhook: "Probar Webhook de Usuarios",
            testParametersWebhook: "Probar Webhook de Parámetros",
            testActivitiesWebhook: "Probar Webhook de Actividades",
            webhookType: {
              users: "Webhook de Usuarios",
              parameters: "Webhook de Parámetros",
              activities: "Webhook de Actividades",
            },
          },
          pagination: {
            previous: "Anterior",
            next: "Siguiente",
            page: "Página",
            showingResults: "Mostrando resultados",
          },
          weekdays: {
            sun: "Dom",
            mon: "Lun",
            tue: "Mar",
            wed: "Mie",
            thu: "Jue",
            fri: "Vie",
            sat: "Sab",
          },
          stepsChart: {
            starsChartTitle: "Estatus de Actividad",
            starsChartStatusSedentary: "Sedentaria",
            starsChartStatusLow: "Baja",
            starsChartStatusModerate: "Moderada",
            starsChartStatusHigh: "Alta",
            stepsThisWeek: "Pasos este día",
            stepsLastWeek: "Promedio de pasos semana pasada",
            minRecommendedValue: "Mínimo de pasos recomendado",
            dayOfWeek: "Día de la semana",
            steps: "Pasos",
            legend1:
              "* Calificación de actividad basada en los últimos 7 días de total de pasos",
            legend2:
              "* Distribución del actividad basada en 14 días de total de pasos",
          },
          sleepChart: {
            sleepScore: "Calificación de Sueño",
            shortSleep: "Sueño corto",
            optimalSleep: "Sueño óptimo",
            longSleep: "Sueño largo",
            sleepDistribution: "Distribución del Sueño",
            lessThan6Hours: "Menos de 6 horas",
            between6And9Hours: "6 a 9 horas",
            moreThan9Hours: "Más de 9 horas",
            legend1:
              "* Calificación de sueño basada en los últimos 3 días de duración total del sueño",
            legend2:
              "* Distribución del sueño basada en la duración total del sueño en los últimos 14 días",
          },
          activitiesChart: {
            title: "Calificación del Tiempo Activo",
            minutes: "minutos",
            title2: "Distribución del Tiempo Activo",
            legend1:
              "* Calificación del tiempo activo basado en la recomendación de 180 minutos de actividad por semana",
            legend2:
              "* Distribución del tiempo activo basado en la duración total de las actividades en los últimos 7 días",
          },
          intradayHeartRate: {
            title: "Frecuencia Cardiaca Durante el Día",
            beatsPerMinute: "lpm",
            time: "hora",
          },
        },
      },
      pt: {
        webhooks: {
          webhookTests: "Testes de Webhooks",
          testUsersWebhook: "Testar Webhook de Usuários",
          testParametersWebhook: "Testar Webhook de Parâmetros",
          testActivitiesWebhook: "Testar Webhook de Atividades",
          webhookType: {
            users: "Webhook de Usuários",
            parameters: "Webhook de Parâmetros",
            activities: "Webhook de Atividades",
          },
        },
        translation: {
          pagination: {
            previous: "Anterior",
            next: "Próximo",
            page: "Página",
            showingResults: "Mostrando resultados",
          },
          weekdays: {
            sun: "Dom",
            mon: "Seg",
            tue: "Ter",
            wed: "Qua",
            thu: "Qui",
            fri: "Sex",
            sat: "Sáb",
          },
          stepsChart: {
            starsChartTitle: "Status de Atividade",
            starsChartStatusSedentary: "Sedentário",
            starsChartStatusLow: "Baixo",
            starsChartStatusModerate: "Moderado",
            starsChartStatusHigh: "Alto",
            stepsThisWeek: "Passos deste dia",
            stepsLastWeek: "Média de passos na última semana",
            minRecommendedValue: "Mín. de passos recomendados",
            dayOfWeek: "Dia da semana",
            steps: "Passos",
            legend1:
              "* Status de atividade baseado nos últimos 7 dias de passos totais",
            legend2:
              "* Distribuição de atividades baseada nos últimos 14 dias de duração total de atividades",
          },
          sleepChart: {
            sleepScore: "Pontuação de Sono",
            shortSleep: "Sono curto",
            optimalSleep: "Sono ideal",
            longSleep: "Sono longo",
            sleepDistribution: "Distribuição do sono",
            lessThan6Hours: "Menos de 6 horas",
            between6And9Hours: "De 6 a 9 horas",
            moreThan9Hours: "Mais de 9 horas",
            legend1:
              "* Pontuação de sono baseada nos últimos 3 dias de duração total de sono",
            legend2:
              "* Distribuição do sono baseada nos últimos 14 dias de duração total de sono",
          },
          activitiesChart: {
            title: "Pontuação de Tempo Ativo",
            minutes: "minutos",
            title2: "Distribuição de Tempo Ativo",
            legend1:
              "* Pontuação de tempo ativo baseada na recomendação de 180 minutos de atividade por semana",
            legend2:
              "* Distribuição de tempo ativo baseada nos últimos 7 dias de duração de atividades",
          },
          intradayHeartRate: {
            title: "Intraday Heart Rate",
            beatsPerMinute: "bpm",
            time: "time",
          },
        },
      },
    },
  });

export default i18n;
