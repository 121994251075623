import dayjs from "dayjs";
import { TFunction } from "i18next";

const recommendedRange = { min: 85 };
const calendarDays = 7;
const today = new Date().setHours(0, 0, 0, 0);

export async function ParseAvgHeartRateData(
  parametersResult: any,
  t: TFunction<"translation", undefined>
) {
  const minRecommended = recommendedRange.min;
  const parametersResultArrayPrev = Array.isArray(parametersResult)
    ? parametersResult
    : [parametersResult];

  let parametersResultArray = parametersResultArrayPrev.filter(
    (element) => element.date !== undefined
  );

  const thisWeekPoints: number[] = [];
  const dataPoints: any = [];

  let lastWeekTotal = 0;

  for (let i = 0; i < calendarDays; i++) {
    const thisWeekDate = dayjs(today)
      .subtract(calendarDays - i, "day")
      .toDate();
    const lastWeekDate = dayjs(today)
      .subtract(calendarDays * 2 - i, "day")
      .toDate();

    const thisWeekElement = parametersResultArray.find(
      (element) =>
        dayjs(element.date).toDate().getTime() === thisWeekDate.getTime()
    );

    const lastWeekElement = parametersResultArray.find(
      (element) =>
        dayjs(element.date).toDate().getTime() === lastWeekDate.getTime()
    );

    const dataPoint = {
      x: `${dayjs(thisWeekDate).format("ddd DD/MM")}`,
      avgHeartRateThisWeek: thisWeekElement?.value ?? 0,
      avgHeartRateLastWeek: lastWeekElement?.value ?? 0,
      minRecommendedValue: minRecommended,
    };

    lastWeekTotal += lastWeekElement?.value ?? 0;

    dataPoints.push(dataPoint);

    thisWeekPoints.push(thisWeekElement?.value ?? 0);
  }

  const avgLastWeek = Math.round(lastWeekTotal / dataPoints.length);

  for (let i = 0; i < dataPoints.length; i++) {
    dataPoints[i].avgHeartRateLastWeek = avgLastWeek;
  }

  const averageHeartRate = calculateAverageHeartRateNoZeroes(thisWeekPoints);
  const stars = getStarsNumber(averageHeartRate) ?? 1;

  return {
    lineChart: {
      axis: {
        xLabel: "Day of week",
        yLabel: "Average Heart Rate",
      },
      data: dataPoints,
    },
    starsChart: {
      stars: stars || 0, // Ensure stars is not undefined
      average: averageHeartRate,
      title: getStarsTitle(stars) || "", // Provide a default value for the title
    } as { stars: number; average: number; title: string }, // Define the type for starsChart object
  };
}

function calculateAverageHeartRateNoZeroes(data: number[]) {
  const dataWithoutZeroes = data.filter((x) => x > 0);
  const sum = dataWithoutZeroes.reduce((a, b) => a + b, 0);
  const average = sum / dataWithoutZeroes.length;
  return average;
}

function getStarsNumber(average: number) {
  if (average < 500) return 1;
  if (average < 1000) return 2;
  if (average < 1500) return 3;
  return 4;
}

function getStarsTitle(stars: number) {
  switch (stars) {
    case 2:
      return "Low";
    case 3:
      return "Moderate";
    case 4:
      return "High";
    default:
      return "Sedentary";
  }
}
